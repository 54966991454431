<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>#{{ id }} - {{ body.title }}</h1>
        </div>
      </div>
    </section>
    <div
      style="background-color: white; padding-top: 36px; padding-bottom: 150px"
    >
      <div class="container">
        <div class="card p-4">
          <div class="card-body">
            <p v-html="body.xbody"></p>
          </div>
          <div class="card-footer text-center">
            {{ $durationFormatFullDate(body.createdAt) }}
          </div>
        </div>

        <div class="mt-4" v-for="item of body.list">
          <div class="card p-4">
            <div class="card-header" v-if="item.usersId"> 
              {{ item.usersId.fname }} {{ item.usersId.lname }}
            </div>
            <div class="card-header" v-if="item.adminsId"> 
              {{ item.adminsId.fullName }} 
            </div>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p v-html="body.xbody"></p>
                <footer class="blockquote-footer">
                  {{ $durationFormatFullDate(item.createdAt) }}
                </footer>
              </blockquote>
            </div>
          </div>
          <hr />
        </div>

        <div class="card p-4 mt-5">
          <div class="card-body">
            <textarea class="form-control" id="id" v-model="comment.xbody" />
          </div>
          <div class="card-footer text-center">
            <button class="btn btn-info" @click="add">
              {{ $t("send") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      body: {
        xbody: null,
        title: null,
        list: [],
      },
      id: null,
      comment: { xbody: null },
    };
  },

  methods: {
    add() {
      if (this.comment.xbody) {
        const user = JSON.parse(localStorage.userKB);

        this.$http
          .post("supportTickets/addcommant", {
            ...this.comment,
            usersId: user.id,
            supportTicketsId:this.id
          })
          .then((res) => {
            this.$swal.fire({
              icon: "success",
              title: this.$t("Success"),
              text: this.$t("Success Done"),
              showConfirmButton: false,
              timer: 1500,
            });
            this.comment = {
              xbody: null,
            };

            this.getDate();
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: this.$t("Error"),
          text: this.$t("Something has gone wrong"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getDate() {
      this.id = this.$route.params.id;

      this.$http.get("supportTickets/" + this.id).then((res) => {
        this.body = res.data.data;
      });
    },
  },
  async created() {
    this.getDate();
  },
};
</script>

<style></style>
